import PropTypes from "prop-types"
import React from "react"

import styles from "./headline.module.css"

const Slugline = ({ children, tag, visualStyle, align, className }) => {
  align = align ? styles[align] : '';

  return (
    <div className={[styles.headline, styles.slugline, styles[visualStyle], className].join(' ')}>
      <p className={[styles.slugText, align].join(' ')}>
        {children}
      </p>
    </div>
  )
}

Slugline.propTypes = {
  visualStyle: PropTypes.string,
  align: PropTypes.string,
  className: PropTypes.string
}

Slugline.defaultProps = {
  visualStyle: 'h1',
  align: '',
  className: '',
}

export default Slugline
