import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Slugline from "../../../atoms/headline/slugline";
import Text     from "../../../atoms/text/text";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageContact.module.css';

const StageContact = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          bgContact: file(relativePath: { eq: "landingpage/stageContact/heinrichHaeuslerBergmoser.png" }) {
            childImageSharp {
              fluid(maxHeight: 550, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
        }
      `}
      render={data => (
        <Stage
          anchor="kontakt"
          className={styles.stageContact}
        >
          <div className={styles.content}>
            <Headline tag="h2">Kontakt</Headline>
            <Spacer />
            <Slugline visualStyle="h2">Sagen Sie Hallo!</Slugline>
            <Text className={styles.text}>
              Wir schätzen das gesprochene Wort, denn persönlicher Kontakt ist gerade heute wertvoller denn je. Wir sind für Sie da und freuen uns über Ihre Fragen!
              <br /><br />
              Klaus Bergmoser (Regionalleitung)<br />
              <span className={styles.label}>F</span> +49 89 55152 13<br />
              <span className={styles.label}>M</span> <a href="mailto:k.bergmoser@heinrich-haeusler.de">k.bergmoser@heinrich-haeusler.de</a>
            </Text>
          </div>
          <div className={styles.image}>
            <Img  fluid={data.bgContact.childImageSharp.fluid} />
          </div>
        </Stage>
      )}
    />
  )
}

export default StageContact;
