import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageSatisfaction.module.css';

const Card = ({ title, location, image, children }) => (
  <div className={styles.card}>
    <Img className={styles.cardImage} fluid={image} alt={title} />
    <h3 className={styles.cardHeadline}>{title}</h3>
    <Spacer align="center" className={styles.cardSpacer} />
    <div className={styles.cardLocation}>{location}</div>
    <div className={styles.cardText}>
      {children}
    </div>
  </div>
)

export const cardImage = graphql`
  fragment cardImage on File {
    childImageSharp {
      fluid(maxWidth: 138, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const StageSatisfaction = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          imgStageSatisfaction1: file(relativePath: { eq: "landingpage/stageSatisfaction/johannRueckerlElektromeister.jpg" }) {
            ...cardImage
          },
          imgStageSatisfaction2: file(relativePath: { eq: "landingpage/stageSatisfaction/friedrichLiebhartGmbh.jpg" }) {
            ...cardImage
          },
          imgStageSatisfaction3: file(relativePath: { eq: "landingpage/stageSatisfaction/elektroLiebetrauGmbh.jpg" }) {
            ...cardImage
          },
        }
      `}
      render={data => (
        <Stage className={styles.stageSatisfaction}>
          <Headline tag="h2" className={styles.headline}>Zufriedenheit als Maßstab</Headline>
          <Spacer align="center" />
          <div className={styles.cards}>
            <Card title="Johann Rückerl Elektromeister" location="München" image={data.imgStageSatisfaction1.childImageSharp.fluid}>
              "Alltagswerkzeug und Einkaufen in einem - über den Shop lässt sich quasi alles erledigen."
            </Card>
            <Card title="Friedrich Liebhart GmbH" location="München" image={data.imgStageSatisfaction2.childImageSharp.fluid}>
              "Kostenlose Retouren, kostenloser Kabelschnitt, kostenlose Beratung und obendrein gibt's noch einen Kaffee vor Ort - Service macht halt den Unterschied!"
            </Card>
            <Card title="Elektro Liebetrau GmbH" location="Pastetten" image={data.imgStageSatisfaction3.childImageSharp.fluid}>
              "Häusler überzeugt! Hier trifft traditionelle Gründlichkeit auf zeitgemäße Geschwindigkeit."
            </Card>
          </div>
        </Stage>
      )}
    />
  )
}

export default StageSatisfaction;
