import React from 'react'
import PropTypes from 'prop-types'

import styles from './spacer.module.css';

const Spacer = ({align, className}) => {
  return <div className={[styles.spacer, styles[align], className].join(' ')}></div>
}

Spacer.propTypes = {
  align: PropTypes.oneOf([null, 'center', 'right']),
  className: PropTypes.string
}

Spacer.defaultProps = {
  align: null,
  className: null
}

export default Spacer
