import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Slugline from "../../../atoms/headline/slugline";
import Text     from "../../../atoms/text/text";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageDigital.module.css';

const StageDigital = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          imgStageDigital: file(relativePath: { eq: "landingpage/stageDigital/Login_Onlinewelt.png" }) {
            childImageSharp {
              fluid(maxWidth: 583, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
        }
      `}
      render={data => (
        <Stage anchor="digital" className={styles.stageDigital}>
          <div className={styles.content}>
            <Headline tag="h2">Optimal auch digital</Headline>
            <Spacer />
            <Slugline visualStyle="h2">Webshop, App und Schnittstellen</Slugline>
            <Text>
              Mit unseren digitalen Möglichkeiten bieten wir Ihnen eine Vielzahl an Onlinetools an. Was uns bei deren Entwicklung besonders antreibt, ist die Frage, wie wir Ihnen den Arbeitsalltag weiter erleichtern können.
            </Text>
          </div>
          <div className={styles.image}>
            <Img
              fluid={data.imgStageDigital.childImageSharp.fluid}
              alt="Wilkommen"
            />
          </div>

        </Stage>
      )}
    />
  )
}

export default StageDigital;
