import React from 'react';
import PropTypes from 'prop-types';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Slugline from "../../../atoms/headline/slugline";
import Link     from "../../../atoms/button/link";

import styles   from './stageCta.module.css';

const StageCta = ({ slugline }) => {
  return (
    <Stage className={styles.stageCta}>
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <Slugline visualStyle="h2">{slugline}</Slugline>
          <Headline tag="h2">JETZT KUNDE WERDEN</Headline>
        </div>
        <Link styleType="cta" to="/signup">Zur Anmeldung</Link>
      </div>
    </Stage>
  )
}

StageCta.propTypes = {
  /** Link URL */
  slugline: PropTypes.string,

}

StageCta.defaultProps = {
  slugline: '',
}

export default StageCta
