import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Slugline from "../../../atoms/headline/slugline";
import Text     from "../../../atoms/text/text";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageLocations.module.css';

export const loationImage = graphql`
  fragment loationImage on File {
    childImageSharp {
      fluid(maxHeight: 500, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const bgImage = graphql`
  fragment bgImage on File {
    childImageSharp {
      fixed(height: 508, quality: 30) {
        ...GatsbyImageSharpFixed_withWebp
      }
    }
  }
`

const StageLocations = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          imgStageLocation1: file(relativePath: { eq: "landingpage/stageLocation/muenchenCity.jpg" }) {
            ...loationImage
          },
          imgStageLocation2: file(relativePath: { eq: "landingpage/stageLocation/muenchenFreimann.jpg" }) {
            ...loationImage
          },
          imgStageLocation3: file(relativePath: { eq: "landingpage/stageLocation/ingolstadt.jpg" }) {
            ...loationImage
          },
          imgStageLocation4: file(relativePath: { eq: "landingpage/stageLocation/filiale.jpg" }) {
            ...loationImage
          },
          imgStageLocation5: file(relativePath: { eq: "landingpage/stageLocation/filialeStandort.jpg" }) {
            ...loationImage
          }
          imgStageLocationLeft: file(relativePath: { eq: "landingpage/stageLocation/werkzeugLinks.jpg" }) {
            ...bgImage
          }
          imgStageLocationRight: file(relativePath: { eq: "landingpage/stageLocation/werkzeugRechts.jpg" }) {
            ...bgImage
          }
        }
      `}
      render={data => (
        <Stage anchor="standorte" fullWidth={true} className={styles.stageLocations}>
          <div className={styles.stageWrap}>
            <div className={styles.bgLeft}>
              <Img className={styles.bgLeftImage} style={{position: 'absolute'}} fixed={data.imgStageLocationLeft.childImageSharp.fixed} alt="" />
            </div>

            <div className={styles.contentWrap}>
              <div className={styles.maps}>
                <div className={styles.mapsCol1}>
                  <Img className={styles.image} fluid={data.imgStageLocation1.childImageSharp.fluid} alt="Filiale München-City" title="Filiale München-City" />
                  <Img className={styles.image} fluid={data.imgStageLocation2.childImageSharp.fluid} alt="Filiale München-Freimann" title="Filiale München-Freimann" />
                  <Img className={styles.image} fluid={data.imgStageLocation3.childImageSharp.fluid} alt="Filiale Ingolstadt" title="Filiale Ingolstadt" />
                </div>
                <div className={styles.mapsCol2}>
                  <Img className={styles.image} fluid={data.imgStageLocation4.childImageSharp.fluid} alt="" />
                  <Img className={styles.image} fluid={data.imgStageLocation5.childImageSharp.fluid} alt="" />
                </div>
              </div>
              <div className={styles.content}>
                <Headline tag="h2">Standorte</Headline>
                <Spacer />
                <Slugline visualStyle="h2">Wir sind um's Eck.</Slugline>
                <Text align="left">
                  Filiale München-City<br />
                  Goethestr. 25, 80336 München<br />
                  <br />
                  Filiale München-Freimann<br />
                  Maria Probst Str. 20, 80939 München-Freimann<br />
                  <br />
                  Filiale Ingolstadt<br />
                  Manchinger Str. 86, 85053 Ingolstadt
                </Text>
              </div>
            </div>


            <div className={styles.bgRight}>
              <Img fixed={data.imgStageLocationRight.childImageSharp.fixed} alt="" />
            </div>

          </div>

        </Stage>
      )}
    />

  )
}

export default StageLocations;
