import React from "react";

import LayoutLandingpage  from '../components/LayoutLandingpage';
import Seo                from "../components/atoms/seo/seo";
import StageWelcome       from '../components/landingpage/stages/stageWelcome/StageWelcome';
import StageCta           from '../components/landingpage/stages/stageCta/StageCta';
import StageAboutUs       from '../components/landingpage/stages/stageAboutUs/StageAboutUs';
import StageService       from '../components/landingpage/stages/stageService/StageService';
import StageDigital       from '../components/landingpage/stages/stageDigital/StageDigital';
import StageSatisfaction  from '../components/landingpage/stages/stageSatisfaction/StageSatisfaction';
import StageBrands        from '../components/landingpage/stages/stageBrands/StageBrands';
import StageLocations     from '../components/landingpage/stages/stageLocations/StageLocations';
import StageContact       from '../components/landingpage/stages/stageContact/StageContact';

export default () => (
  <>
    <Seo
      title="Welcome"
    />
    <LayoutLandingpage>
      <StageWelcome />
      <StageCta slugline="Vorteile sichern und" />
      <StageAboutUs />
      <StageService />
      <StageDigital />
      <StageSatisfaction />
      <StageCta slugline="Vorteile, die auf der Hand liegen." />
      <StageBrands />
      <StageLocations />
      <StageContact />
      <StageCta slugline="Vorteile sichern und" />
    </LayoutLandingpage>
  </>
)
