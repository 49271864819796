import PropTypes from "prop-types"
import React from "react"
import { Link as GALink } from "gatsby";
import { camelCase } from 'lodash';

import styles from "./button.module.css"


const Link = ({ children, type, htmlType, to, target, styleType, onClick, className }) => {

  const buttonStyle = styles[camelCase('style-'+styleType)];

  return (
    <GALink
      to={to}
      className={[styles.button, buttonStyle, className].join(' ')}
      target={target}
      rel={target === '_blank' ? 'nofollow noreferrer' : null}
      onClick={onClick}
    >
      {children}
    </GALink>
  )
}

Link.propTypes = {
  /** Tag name */
  type: PropTypes.oneOf(['text', 'button']),
  /** Link URL */
  to: PropTypes.string,
  /** Link target */
  target: PropTypes.string,
  /** Type */
  styleType: PropTypes.oneOf(['default', 'back', 'cta', 'text']),
  /** onClick event */
  onClick: PropTypes.func,
  /** Additional classname for individual styling in the the parent component */
  className: PropTypes.string
}

Link.defaultProps = {
  type: 'text',
  to: null,
  target: '_self',
  styleType: 'default',
  onClick: () => {},
  className: null
}

export default Link
