import React from 'react';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from "./stageService.module.css";

import IconSortiment from "../../../../images/landingpage/icons/sortiment.svg";
import IconDelivery from "../../../../images/landingpage/icons/delivery.svg";
import IconNetwork from "../../../../images/landingpage/icons/network.svg";
import IconOnline from "../../../../images/landingpage/icons/online.svg";



const Card = ({ children, title, icon }) => (
  <div className={styles.card}>
    <div className={styles.cardIcon}>{icon}</div>
    <h3 className={styles.cardTitle}>{title}</h3>
    <div className={styles.cardText}>
      {children}
    </div>
  </div>
);

const StageService = () => {
  return (
    <Stage anchor="service" className={styles.stageService}>
      <Headline tag="h2">Unser Service</Headline>
      <Spacer align="center" />
      <div className={styles.cards}>
        <Card title="Riesiges Sortiment" icon={<IconSortiment />}>
          Rund eine Million Artikel,
          von denen rund 30.000 stets am Lager sind
        </Card>

        <Card title="Schnellste Lieferung" icon={<IconDelivery />}>
          Bestellte Ware bereits zum nächsten Werktag am Wunschort erhalten
        </Card>

        <Card title="Filialnetz" icon={<IconNetwork />}>
          Mit bundesweit über
          60 Filialen immer
          in der Nähe
        </Card>

        <Card title="Onlinewelt" icon={<IconOnline />}>
          Hochfunktionaler Webshop und passende App erleichtern Ihren Arbeitsalltag
        </Card>
      </div>
    </Stage>
  )
}

export default StageService;
