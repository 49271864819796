
import PropTypes from 'prop-types';
import React from "react";
import cssVars from 'css-vars-ponyfill';
import { Helmet } from "react-helmet";

import Header         from './organisms/header/header';
import Footer         from './organisms/footer/footer';
import CookieConsent  from './molecules/cookieConsent/CookieConsent';

import "../styles/landingpage/variables.css";
import "../styles/global.css";
import styles from './layout.module.css';

const menuItems = [
  {
    title: 'Über Uns',
    anchor: 'ueberuns'
  },
  {
    title: 'Unser Service',
    anchor: 'service'
  },
  {
    title: 'Digitale Welt',
    anchor: 'digital'
  },
  {
    title: 'Standorte',
    anchor: 'standorte'
  },
  {
    title: 'Kontakt',
    anchor: 'kontakt'
  }
]

const LayoutLandingpage = ({ children, logoText }) => (
  <div className={["landingpage", styles.layout].join(' ')}>
    <Helmet>
      <script>{cssVars({ /* Fixes for IE11 CSS custom properties */ })}</script>
    </Helmet>
    <Header logoText={logoText} menuItems={menuItems} scrollLogo={true} />
    <main className={styles.main}>{children}</main>
    <Footer />
    <CookieConsent />
  </div>
)

LayoutLandingpage.propTypes = {
  logoText: PropTypes.string
}

LayoutLandingpage.defaultProps = {
  logoText: 'Häusler'
}

export default LayoutLandingpage;
