import React from 'react';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Slugline from "../../../atoms/headline/slugline";
import Link     from "../../../atoms/button/link";
import Text     from "../../../atoms/text/text";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageAboutUs.module.css';

const StageAboutUs = () => {
  return (
    <Stage anchor="ueberuns" className={styles.stageAboutUs}>
        <Headline tag="h2">Über uns</Headline>
        <Spacer />
        <Slugline visualStyle="h2">Elektrogroßartig</Slugline>
        <Text>
          Kaum ein Elektrogroßhandel verbindet Tradition und Moderne so wie Heinrich Häusler: Mit dem Erfahrungsschatz einer rund hundertjährigen Firmengeschichte begegnen wir den vielfältigen Anforderungen, die das Elektrohandwerk an uns stellt.
        </Text>
        <Text>
          Tagtäglich stellen wir uns den Herausforderungen eines dynamischen Marktes und unterstützen mit einer Servicepalette weit über das Gewohnte hinaus. Neben hochfunktionalen digitalen Lösungen und unabhängiger Fachberatung warten wir vor allem mit einem riesigen Sortiment etwa einer Million Artikeln und einer beeindruckender Logistik auf, mit der wir Ihnen übrigens die Lieferung zum nächsten Werktag garantieren.
        </Text>
        <Text>
          Obendrein treffen Sie uns nicht nur in München und Ingolstadt, sondern an bundesweit über 60 weiteren Standorten, um nur einen von vielen Vorteilen zu nennen, die der Zusammenschluss mit Obeta, dem Berliner Urgestein im Elektrogroßhandel mit sich bringt.
        </Text>
        <Link styleType="cta" to="/signup">Zur Anmeldung</Link>
    </Stage>
  )
}

export default StageAboutUs;
