import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Stage    from "../../../molecules/stage/Stage";
import Headline from "../../../atoms/headline/headline";
import Spacer   from "../../../atoms/spacer/Spacer";

import styles   from './stageBrands.module.css';

const Card = ({title, img}) => (
  <div className={styles.card}>
    <Img
      className={styles.image}
      fluid={img}
      alt={title}
    />
  </div>
);

export const brandImage = graphql`
  fragment brandImage on File {
    childImageSharp {
      fluid(maxWidth: 215, quality: 100) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

const StageBrands = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          buschJaeger: file(relativePath: { eq: "landingpage/stageBrands/buschJaeger.png" }) {
            ...brandImage
          },
          hager: file(relativePath: { eq: "landingpage/stageBrands/hager.png" }) {
            ...brandImage
          },
          gira: file(relativePath: { eq: "landingpage/stageBrands/gira.png" }) {
            ...brandImage
          },
          ledvance: file(relativePath: { eq: "landingpage/stageBrands/ledvance.png" }) {
            ...brandImage
          },
          odoBettermann: file(relativePath: { eq: "landingpage/stageBrands/odoBettermann.png" }) {
            ...brandImage
          },
          schneiderElectric: file(relativePath: { eq: "landingpage/stageBrands/schneiderElectric.png" }) {
            ...brandImage
          },
          steinel: file(relativePath: { eq: "landingpage/stageBrands/steinel.png" }) {
            ...brandImage
          },
          jung: file(relativePath: { eq: "landingpage/stageBrands/jung.png" }) {
            ...brandImage
          }
        }
      `}
      render={data => (
        <Stage className={styles.stageBrands}>
          <Headline tag="h2">Beliebte Marken</Headline>
          <Spacer align="center" />
          <div className={styles.cards}>
            <Card
              img={data.buschJaeger.childImageSharp.fluid}
              title="Busch-Jaeger"
            />
            <Card
              img={data.hager.childImageSharp.fluid}
              title=":hager"
            />
            <Card
              img={data.gira.childImageSharp.fluid}
              title="Gira"
            />
            <Card
              img={data.ledvance.childImageSharp.fluid}
              title="Ledvance"
            />
            <Card
              img={data.odoBettermann.childImageSharp.fluid}
              title="OBO Bettermann"
            />
            <Card
              img={data.schneiderElectric.childImageSharp.fluid}
              title="Schneider Electric"
            />
            <Card
              img={data.steinel.childImageSharp.fluid}
              title="steinel"
            />
            <Card
              img={data.jung.childImageSharp.fluid}
              title="JUNG"
            />
          </div>
        </Stage>
      )}
    />
  )
}

export default StageBrands;
