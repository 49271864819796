import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Stage      from "../../../molecules/stage/Stage";
import Headline   from "../../../atoms/headline/headline";
import Slugline   from "../../../atoms/headline/slugline";
import Spacer     from '../../../atoms/spacer/Spacer';

import styles      from './stageWelcome.module.css'

const StageWelcome = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          imgStageWelcome: file(relativePath: { eq: "landingpage/stageWelcome/background.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          },
        }
      `}
      render={data => (
        <Stage
          className={styles.stageWelcome}
          bgImage={data.imgStageWelcome.childImageSharp.fluid}
          bgStyle={{
            // Defaults are overwrite-able by setting one or each of the following:
            backgroundSize: 'contain',
            backgroundPosition: 'calc(70% + 80px) bottom'
          }}
        >
          <Headline>
            Profis wie Sie <br />
            verdienen Profis <br />
            wie uns!
          </Headline>
          <Spacer className={styles.spacer} />
          <Slugline className={styles.slugline}>
            Elektrogroßhandel neu gedacht.<br />
            <br />
            Traditionsbewusstsein trifft Innovationsanspruch -<br />
            <br />
            Willkommen bei Heinrich Häusler!
          </Slugline>
        </Stage>
      )}
    />
  )
}

export default StageWelcome;
